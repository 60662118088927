import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  main: {
    minHeight: "calc(100vh - 40px)",
    paddingTop: "80px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      minHeight: "calc(100vh - 92px)",
    },

    // UI Kit Typography is not responsive
    // so it has to be solved in the scope of the app
    "& .HvTypography-mTitle": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 18,
        lineHeight: "24px",
      },
    },
  },
  article: {
    flexGrow: 1,
  },
}));

export default useStyles;
