import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing("xl"),

    "& > p": {
      margin: `${theme.spacing("xs")}px 0`,
    },
    "& .MuiGrid-item > p": {
      margin: `${theme.spacing("xs")}px 0`,
    },
    "& .HvInput-root": {
      height: 84,
    },
  },
  shareContainer: {
    alignSelf: "flex-end",
    [theme.breakpoints.up("sm")]: {
      textAlign: "right",
    },
  },
  sectionTitle: {
    borderTop: `solid 1px ${theme.hv.palette.atmosphere.atmo4}`,
    paddingTop: theme.spacing("xs"),
    margin: `${theme.spacing("sm")}px 0`,
  },
  actionBar: {
    position: "fixed",
    bottom: 0,
    left: 0,
    backgroundColor: theme.hv.palette.atmosphere.atmo1,
  },
}));

export default useStyles;
