import React, { useState } from "react";

import {
  HvContainer,
  HvGrid,
  HvTypography,
  HvInput,
  HvFileUploader,
  HvTextArea,
  HvRadioGroup,
  HvRadio,
  HvLink,
  HvCheckBox,
  HvActionBar,
  HvButton,
} from "@hv/uikit-react-core";

import { Share } from "@hv/uikit-react-icons";

import useStyles from "./styles";

import { upload, remove } from "../../utils/upload";

export default function Apply() {
  const classes = useStyles();

  const [cvFiles, setCvFiles] = useState([]);
  const [coverLetterFiles, setCoverLetterFiles] = useState([]);

  return (
    <HvContainer maxWidth="md" component="form" className={classes.root}>
      <HvTypography variant="mTitle" component="h1">
        Senior Software Development Engineer
      </HvTypography>

      <HvGrid container spacing={0}>
        <HvGrid item xs={12} sm={10}>
          <HvTypography variant="normalText" component="p">
            <HvTypography variant="highlightText" component="span">
              Requisition ID:&nbsp;
            </HvTypography>
            1008111
          </HvTypography>
          <HvTypography variant="normalText" component="p">
            <HvTypography variant="highlightText" component="span">
              Note:&nbsp;
            </HvTypography>
            All fields with an asterisk (*) are “Required”.
          </HvTypography>
        </HvGrid>
        <HvGrid item xs={12} sm={2} className={classes.shareContainer}>
          <HvButton startIcon={<Share />} category="ghost">
            Share
          </HvButton>
        </HvGrid>
      </HvGrid>

      <HvGrid container component="section">
        <HvGrid item xs={12}>
          <HvTypography variant="sectionTitle" className={classes.sectionTitle} component="h2">
            Personal details
          </HvTypography>
        </HvGrid>

        <HvGrid item xs={12} sm={6} md={6}>
          <HvInput name="firstName" required label="First Name" placeholder="Enter text" />
        </HvGrid>
        <HvGrid item xs={12} sm={6} md={6}>
          <HvInput name="lastName" required label="Last Name" placeholder="Enter text" />
        </HvGrid>
        <HvGrid item xs={12} sm={6} md={6}>
          <HvInput name="email" type="email" required label="Email" placeholder="Enter text" />
        </HvGrid>
        <HvGrid item xs={12} sm={6} md={6}>
          <HvInput name="phoneNumber" required label="Phone Number" placeholder="Enter text" />
        </HvGrid>
      </HvGrid>

      <HvGrid container component="section">
        <HvGrid item xs={12}>
          <HvTypography variant="sectionTitle" className={classes.sectionTitle} component="h2">
            Location
          </HvTypography>
        </HvGrid>

        <HvGrid item xs={12} sm={6} md={6}>
          <HvInput name="streeAddress" required label="Street Address" placeholder="Enter text" />
        </HvGrid>
        <HvGrid item xs={6} sm={3} md={3}>
          <HvInput name="zipCode" required label="Zip Code" placeholder="Enter text" />
        </HvGrid>
        <HvGrid item xs={6} sm={3} md={3}>
          <HvInput name="city" required label="City" placeholder="Enter text" />
        </HvGrid>
      </HvGrid>

      <HvGrid container component="section">
        <HvGrid item xs={12}>
          <HvTypography variant="sectionTitle" className={classes.sectionTitle} component="h2">
            Professional details
          </HvTypography>
        </HvGrid>

        <HvGrid item xs={12} sm={6} md={6}>
          <HvInput name="linkedinProfile" label="LinkedIn Profile" placeholder="Enter text" />
        </HvGrid>
        <HvGrid item xs={12} sm={6} md={6}>
          <HvInput name="githubProfile" label="GitHub Profile" placeholder="Enter text" />
        </HvGrid>
        <HvGrid item xs={12} sm={12} md={6}>
          <HvFileUploader
            fileList={cvFiles}
            onFilesAdded={(newFiles) => {
              newFiles.forEach((newFile) => upload(newFile, setCvFiles));
            }}
            onFileRemoved={(removedFile) => {
              remove(removedFile, setCvFiles);
            }}
            acceptedFiles={["pdf", "docx", "jpg", "png"]}
            labels={{ dropzone: "Upload CV/Resume*", sizeWarning: "Max. file size" }}
            maxFileSize={5 * 1000 ** 2}
          />
        </HvGrid>
        <HvGrid item xs={12} sm={12} md={6}>
          <HvFileUploader
            fileList={coverLetterFiles}
            onFilesAdded={(newFiles) => {
              newFiles.forEach((newFile) => upload(newFile, setCoverLetterFiles));
            }}
            onFileRemoved={(removedFile) => {
              remove(removedFile, setCoverLetterFiles);
            }}
            acceptedFiles={["pdf", "docx", "jpg", "png"]}
            labels={{ dropzone: "Upload Cover Letter", sizeWarning: "Max. file size" }}
            maxFileSize={5 * 1000 ** 2}
          />
        </HvGrid>
        <HvGrid item xs={12} sm={12} md={12}>
          <HvTextArea name="comments" label="Comments" placeholder="Enter text" rows={4} />
        </HvGrid>
      </HvGrid>

      <HvGrid container component="section">
        <HvGrid item xs={12}>
          <HvTypography variant="sectionTitle" className={classes.sectionTitle} component="h2">
            Legal information
          </HvTypography>
        </HvGrid>

        <HvGrid item xs={12} sm={12} md={6}>
          <HvRadioGroup
            name="authorizedToWork"
            orientation="horizontal"
            label="Are you legally authorized to work in the country where this job is located?"
            required
          >
            <HvRadio label="Yes" value="yes" />
            <HvRadio label="No" value="no" />
          </HvRadioGroup>
        </HvGrid>
        <HvGrid item xs={12} sm={12} md={6}>
          <HvRadioGroup
            name="requireSponsorship"
            orientation="horizontal"
            label="Will you now, or in the future, require sponsorship for employment?"
            required
          >
            <HvRadio label="Yes" value="yes" />
            <HvRadio label="No" value="no" />
          </HvRadioGroup>
        </HvGrid>
      </HvGrid>

      <HvGrid container component="section">
        <HvGrid item xs={12}>
          <HvTypography variant="sectionTitle" className={classes.sectionTitle} component="h2">
            Data Protection and Consent Form
          </HvTypography>
          <HvTypography variant="normalText" component="p">
            Please <HvLink route="#">click here</HvLink> to read the&nbsp;
            <HvTypography variant="highlightText" component="span">
              Hitachi Vantara Data Protection Privacy Statement
            </HvTypography>
            .
          </HvTypography>
          <HvTypography variant="normalText" component="p">
            By checking the box labeled “
            <HvTypography variant="highlightText" component="span">
              I Consent
            </HvTypography>
            ” on this submission page and submitting my personal data to the Company, I confirm that
            I have read, understand, and consent to the terms and conditions, including those which
            are provided via hyperlinks on this page, in which my personal data will be processed by
            the Company.
          </HvTypography>

          <HvCheckBox name="dataProtectionConsent" label="I Consent" value="yes" required />
        </HvGrid>
      </HvGrid>

      <HvActionBar className={classes.actionBar}>
        <HvButton type="submit" category="ghost">
          Submit Application
        </HvButton>
      </HvActionBar>
    </HvContainer>
  );
}
